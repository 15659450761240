import PropTypes from 'prop-types'
import { twMerge } from 'tailwind-merge'

import { formatDate } from './utils'

import {
  BannerLinkDate,
  BannerLinkImage,
  BannerLinkSubtitle,
  BannerLinkTag,
  BannerLinkTitle,
  BannerLinkWrapper,
  BannerLinkContent,
  BannerLinkCTA,
} from './components'
import Link from '@sholdi/primitives/atoms/Link'
import Box from '@sholdi/primitives/atoms/Box'

const BannerLink = ({
  variant = 'round',
  banner,
  children,
  disabledLinks = false,
  className,
  ...props
}) => {
  const {
    imagePath,
    title,
    subtitle,
    tag,
    date,
    href = '',
    cta,
    color,
    backgroundColor,
  } = banner
  const isGridCard = variant === 'gridCard'

  const colorClass = (color ?? isGridCard) ? 'text-white' : 'text-primary-text'

  return (
    <BannerLinkWrapper
      variant={variant}
      className={twMerge('overflow-hidden relative', className)}
      {...props}
    >
      <div className="flex items-center justify-center flex-col w-full h-full">
        <BannerLinkImage
          alt={banner.title.toLowerCase().replace(' ', '-')}
          src={imagePath}
          variant={variant}
          objectFit="cover"
          shape={variant === 'round' ? 'circle' : 'rectangle'}
          className={
            isGridCard
              ? 'w-full h-full absolute inset-0 transition-all duration-300 ease-in-out delay-200 hover:scale-[1.015]'
              : ''
          }
        />
        {children}

        <BannerLinkContent
          isGridCard={isGridCard}
          contentPosition={banner.contentPosition}
          className="z-[5]"
        >
          <div
            className={twMerge(
              'w-min p-2 rounded',
              isGridCard
                ? 'min-w-full md:min-w-[50%] transition-all duration-250 ease-in-out hover:scale-[1.025]'
                : 'min-w-full banner-content',
              backgroundColor ? 'relative backdrop-blur-sm' : '',
            )}
          >
            {backgroundColor && (
              <Box
                className={twMerge(
                  'absolute top-0 left-0 w-full h-full -z-[1] opacity-25',
                  backgroundColor,
                )}
              />
            )}
            {tag && !isGridCard && <BannerLinkTag variant={variant}>{tag}</BannerLinkTag>}
            {!isGridCard && (
              <BannerLinkDate variant={variant}>{formatDate(date)}</BannerLinkDate>
            )}
            <BannerLinkTitle
              as="h3"
              variant={variant}
              className={twMerge(
                'drop-shadow-lg',
                colorClass,
                banner.backgroundColor ? 'p-2 rounded' : '',
              )}
              style={{
                color: banner.color,
                backgroundColor: banner.backgroundColor,
              }}
            >
              {title}
            </BannerLinkTitle>
            {cta && !disabledLinks && (
              <BannerLinkCTA
                variant={variant}
                href={href}
                className={twMerge('drop-shadow-sm z-[4]', colorClass)}
              >
                {cta}
              </BannerLinkCTA>
            )}
            {!isGridCard && (
              <BannerLinkSubtitle className={colorClass} variant={variant}>
                {subtitle}
              </BannerLinkSubtitle>
            )}
          </div>
          {!disabledLinks && (
            <Link
              href={href}
              style={{
                fontSize: 0,
                lineHeight: 0,
              }}
              className="absolute top-0 left-0 w-full h-full z-[3]"
            >
              {title}
            </Link>
          )}
        </BannerLinkContent>
      </div>
    </BannerLinkWrapper>
  )
}

BannerLink.propTypes = {
  children: PropTypes.node,
  variant: PropTypes.string,
  banner: PropTypes.shape({
    imagePath: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    tag: PropTypes.string,
    href: PropTypes.string,
    date: PropTypes.string,
    contentPosition: PropTypes.string,
    cta: PropTypes.string,
    backgroundColor: PropTypes.string,
    color: PropTypes.string,
    size: PropTypes.string,
  }),
  isGallery: PropTypes.bool,
  disabledLinks: PropTypes.bool,
  className: PropTypes.string,
}

BannerLink.displayName = 'BannerLink'

export default BannerLink
